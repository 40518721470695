.provider-input {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 80%;
	padding: 7px;
	border: none;
	border-bottom: 1px solid #ddd;
	background: transparent;
	margin-bottom: 10px;
	font: 16px Arial, Helvetica, sans-serif;
	height: 45px;
}

.provider-input:disabled {
	opacity: 0.3;
}

#checkboxes {
	background-color: #fff;
	margin-left: 25px;
	padding: 10px;
}

.checkbox {
	margin: 5px;
}

.checkbox label {
	min-height: 21px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
	line-height: 22px;
}

.c-checkbox span {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-left: -20px;
	width: 20px;
	height: 20px;
	border-radius: 2px;
	border: 1px solid #ccc;
	text-align: center;
}

.c-checkbox span:before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	text-align: center !important;
	font-size: 12px;
	line-height: 18px;
	vertical-align: middle;
}

.c-checkbox input[type='checkbox'] {
	position: absolute;
	margin: 4px 0 0;
	opacity: 0;
	margin-right: 5px;
}

.c-checkbox input[type='checkbox']:checked {
	color: #fff;
	transition: color 0.3s ease-out;
}

.c-checkbox input[type='checkbox']:checked + span {
	border-color: #5d9cec;
	background-color: #5d9cec;
}

.c-checkbox input[type='checkbox']:checked + span:before {
	color: #fff;
	opacity: 1;
	transition: color 0.3s ease-out;
}

.date-picker {
	left: -101px;
}

.remove_this_slobo {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	background-color: transparent;
}

/*Temp login styles- will be removed by Slobo */
.btn_green:hover {
	background-color: #72aa1e;
	border: 2px solid #72aa1e;
}

.login_wrapper .login_dialog .login_box {
	background-color: transparent;
	border-radius: 20px;
}

.edit_time_V {
	width: 150px;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid rgba(143, 164, 184, 0.5);
}
.single_date_V {
	width: 80px;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid rgba(143, 164, 184, 0.5);
}

.market_wrapper_bonus {
	padding: 30px;
	height: 25%;
}

.market_label_bonus {
	width: 20% !important;
}

.label_content_bonus {
	height: 100% !important;
	width: 100% !important;
	font-size: 15px;
}

.market_odd_list_bonus {
	width: 80% !important;
	height: 100%;
}

.game_wrap_bonus {
	height: 55px !important;
}

.game_label_bonus {
	font-size: 16px;
}

.game_odd_bonus {
	height: 52px !important;
	text-align: center;
	font-size: 16px;
}

.start_time_bonus {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
}

.start_time_bonus .time {
	font-weight: 700;
}

.refresh_wrapper {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	height: 42px;
	padding: 0 15px 0 8px;
	margin-left: auto;
}

.refresh_wrapper .tool_item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	color: #8fa4b8;
	font-size: 24px;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.btn_pmc_V {
	margin-right: 10px !important;
	margin-left: -12px !important;
}

.error-boundary {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 30%;
	left: 40%;
}

.error-boundary__text {
	font-size: 1.7rem;
}

.error-boundary__error {
	color: #e35454;
	padding: 20px;
}

.btn_list_V {
	border-radius: 0px !important;
}
.new_list_wrapper_V {
	margin-left: auto;
	margin-right: 90px;
	width: 60%;
	margin-bottom: 10px;
}

.font_size_14 {
	font-size: 14px !important;
}

/* .list-builder_plan-scroll {
	max-height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
} */

.list-builder_align-lists {
	justify-content: flex-start !important;
	text-transform: none !important;
	padding-left: 37px !important;
}

.builder_odds {
	display: flex;
	margin-left: auto !important;
	flex-basis: 32% !important;
	align-items: center;
	justify-content: space-evenly;
}

.builder_odds > * {
	flex-basis: 20%;
}

.width_5_perc {
	width: 5%;
}

.width_10_perc {
	width: 10%;
}

.width_15_perc {
	width: 15%;
}

.width_20_perc {
	width: 20%;
}
.width_30_perc {
	width: 33.333%;
}

.width_50_perc {
	width: 50%;
}

.width_60_perc {
	width: 60%;
}

.width_80_perc {
	width: 80%;
}

.y_auto_overflow {
	overflow-y: auto;
}

.rtl_direction {
	direction: rtl;
}

.ltr_direction {
	direction: ltr;
}

.min_height_oddtypes_list {
	min-height: 480px;
}

.void_timeframes_wrap {
	height: 680px;
}

.void_timeframe_checkbox {
	overflow-y: scroll;
}

.void_timeframe:hover {
	cursor: pointer;
}

.void_timeframe.selected {
	border: 1px solid;
	color: #1180b5;
	background-color: rgba(25, 166, 234, 0.12);
	border-color: rgba(25, 166, 234, 0.3);
}

.icon_form_V {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;
}

.icon_form_V .row {
	flex: 1 0 50%;
	justify-content: space-evenly;
}

.flex {
	flex: 1 !important;
}

.channel_connection .list_wrapper.toggle .list_item {
	display: none !important;
}

.hide_scroll::-webkit-scrollbar {
	display: none;
}

.td_standings_input input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	height: 28px;
	/* padding-left: 6px; */
	color: #556373;
	cursor: pointer;
	pointer-events: all;
	border-radius: 4px;
	border: 2px solid transparent;
	background-color: transparent;
	-webkit-transition: all 0s ease-in-out;
	transition: all 0s ease-in-out;
	text-align: center;
}

.td_standings_input input:hover {
	background-color: #fff;
	border: 2px solid rgba(141, 162, 182, 0.8);
}

.td_standings_input input:focus,
.td_standings_input input:active {
	background-color: #fff;
	border: 2px solid rgba(25, 166, 234, 0.8);
	-webkit-box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.4);
	box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.4);
}

.form_result {
	padding: 1px;
	height: 24px;
	width: 24px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.green_bg_light_hover:hover {
	background-color: #7fc418 !important;
}

.yellow_bg_light_hover:hover {
	background-color: #f7cb63 !important;
}

.red_bg_light_hover:hover {
	background-color: #e35d64 !important;
}

.error_input_V {
	background-color: #fff;
	border: 2px solid #e35454 !important;
	box-shadow: 0 0 15px 1px #f25d5d !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

.fadeInDown_V {
	-webkit-animation: fadeInDown 0.2s ease-in-out;
	animation: fadeInDown 0.2s ease-in-out;
}

.special_header {
	position: sticky;
	top: 80px;
	background-color: #fff;
	z-index: 70;
}

.result_special_value {
	-webkit-box-direction: normal !important;
	box-sizing: border-box;
	outline: 0;
	-webkit-font-smoothing: antialiased;
	font-family: 'Open Sans', sans-serif;
	border-radius: 3px;
	transition: all 0.2s ease-in-out;
	border: 1px solid rgba(143, 164, 184, 0.4);
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	margin: 0 8px 8px 0;
	padding: 0 8px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	color: #73879c;
	background-color: #fff;
	user-select: none;
	height: 30px;
	width: 55px;
}

.width_90 {
	width: 90px !important;
}

.min_width_50 {
	min-width: 50px !important;
}

.list_6_items {
	max-height: 192px !important;
}

.list_5_items {
	max-height: 162px !important;
}

.timeline_wrapper_item {
	display: flex;
	flex-direction: row !important;
	flex-wrap: nowrap;
}
.changes_wrapper {
	display: flex;
	flex-direction: column;
	flex-basis: 70%;
}

.time_point::after {
	content: none !important;
}
.time_point::before {
	content: none !important;
}

.date_height {
	height: 32px !important;
	border-right: none !important;
}

.tag_item.orange {
	color: #fff !important;
	background-color: #fcac32 !important;
}

.result_index {
	background-color: #d8ebbc !important;
}
.tag_item.custom {
	padding: 15px 20px;
	color: #fff;
	margin-left: 25px;
	font-size: 14px;
	border-radius: 4px;
}

.market_odd_list_wide {
	width: calc(100% - 340px) !important;
}

.market_label_small {
	width: 220px !important;
}
.disabled_V {
	opacity: 0.9 !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
	pointer-events: none !important;
}

.currency_table {
	width: 25% !important;
	margin-left: 300px !important;
	margin-top: 80px !important;
}

.currency_converter {
	width: 20% !important;
	margin-left: 170px !important;
	margin-top: 80px !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.currency_converter input {
	height: 40px;
	font-size: 17px;
	padding: 0 10px;
	border: 2px solid #abc6e4;
	text-align: center;
}

.currency_converter input:focus {
	background-color: #fff;
	border: 2px solid rgba(25, 166, 234, 0.6);
	-webkit-box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.2);
	box-shadow: 0 0 20px 2px rgba(25, 166, 234, 0.2);
}

.currency_converter_dropdown {
	padding: 5px;
	border: 0.5px solid #abc6e4;
	border-radius: 5px;
}

.display_inline {
	display: inline !important;
}

.input_padding {
	padding: 2px 8px !important;
}

.display_block_V {
	display: block !important;
}

.similar_ticket_field {
	color: #8795a3;
}

.item_wrap .stake_btn {
	width: 45px !important;
}

.padding_bottom_75 {
	padding-bottom: 75px !important;
}

.tennis_ball {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: #feb200;
	margin-left: 10px;
}

.flex_wrap {
	flex-wrap: wrap;
}

.print-accordion {
	width: 100%;
}

.print-accordion_item {
	display: flex;
	align-items: center;
	width: max-content;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

.print-accordion_item:hover,
.print-accordion_item.toggle {
	color: #19a6ea;
	font-weight: 700;
}

.print-accordion_item .print-accordion_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
}

.print-accordion_item .toggle_ico {
	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 1;
	width: 32px;
	height: 32px;
	font-size: 24px;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.print-accordion_item.toggle .toggle_ico {
	opacity: 1;
	width: 32px;
	height: 32px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.print-accordion_item .toggle_ico--2 {
	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 1;
	width: 32px;
	height: 32px;
	font-size: 24px;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.print-accordion_item.toggle .toggle_ico--2 {
	opacity: 1;
	width: 32px;
	height: 32px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.print-accordion_list {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: all 0.6s ease-in-out;
}

.print-accordion_list.toggle {
	height: fit-content;
	opacity: 1;
	overflow: auto;
}

.side_v {
	color: #19a6ea !important;
}

.insert_row_v {
	opacity: 0;
	padding-right: 10px !important;
}

.csv_row:hover .insert_row_v {
	opacity: 1;
}

.rich-text-editor {
	min-height: 200px;
}

.preview-email-modal {
	height: 290px;
}

.preview-email {
	height: 214px;
	width: 800px;
}

.preview-email-preview {
	min-height: 234px;
	max-height: 360px;
	width: 800px;
}

.rich-text-editor-email {
	min-height: 200px;
	max-height: 360px;
	width: 800px;
}

.rdw-editor-wrapper {
	padding-right: 45px !important;
}

.rdw-suggestion-dropdown {
	position: relative !important;
	min-width: 300px !important;
	max-height: 300px !important;
	width: 80% !important;
}

.rdw-mention-link {
	line-height: 19px !important;
}

.date-picker-override .react-datepicker.date-and-time {
	display: flex !important;
}

.date-picker-override .react-datepicker__month-container {
	float: none !important;
}

.date-picker-override .react-datepicker__time-container {
	float: none !important;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 90px;
}

.transactions-table-height-override {
	height: calc(100vh - 350px) !important;
	overflow: hidden;
}

.notifications-table-height-override {
	height: calc(100vh - 208px) !important;
	overflow: hidden;
}

.notifications-table-height-override {
	height: calc(100vh - 208px) !important;
	overflow: hidden;
}

.risk_online_user_name {
	cursor: pointer;
	padding: 2px 10px 2px 2px;
	text-decoration: underline;
}

.risk_online_user_name:hover {
	border: thin solid rgba(192, 192, 192, 0.65);
	border-radius: 5px;
	padding: 2px 10px 2px 1px;
}

.online_user_description {
	resize: none;
	height: 200px;
}
.market_odd_list_flex {
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
}

.max_height_300 {
	max-height: 300px;
	overflow: auto;
}

.wide_description {
	width: 100% !important;
}

.tag_item.disabled_v {
	opacity: 0.4 !important;
}

.file_upload {
	width: 100%;
	/* margin-left: 10px; */
	border: none;
	padding: 0;
}

.file_upload::-webkit-file-upload-button {
	visibility: hidden;
	margin-left: -70px;
}

.file_upload {
	/* content: 'Select'; */
	/* display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center; */
	height: 24px;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 10px;
	border-radius: 3px;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	white-space: nowrap;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #8da2b6;
	color: #8da2b6;
	border: 1px solid #8da2b6;
	background-color: rgba(141, 162, 182, 0);
}

.file_upload:hover::before,
.file_upload:active::before,
.file_upload:focus::before {
	background: rgba(33, 22, 70, 0.1);
}

.add_document_preview {
	width: 90%;
	height: auto;
	min-height: 300px;
	object-fit: contain;
	padding: 30px;
}

.add_iframe_preview {
	width: 90%;
	height: auto;
	min-height: 50vh;
	object-fit: contain;
	padding: 30px;
}

.disable_resize {
	resize: none;
}

.no_background {
	background: none;
}
.horizontal_scroll {
	overflow-x: auto;
}
table tbody tr:nth-child(even) {
	background-color: #f3f6f8;
}
.bg_row_color_0 {
	background-color: rgb(234 249 253) !important;
}
.bg_row_color_1 {
	background-color: rgb(246, 237, 250) !important;
}
.bg_row_color_2 {
	background-color: rgb(241 253 234) !important;
}
.bg_row_color_3 {
	background-color: rgb(253 253 234) !important;
}
.bg_row_color_4 {
	background-color: rgb(253, 234, 234) !important;
}
.bg_row_color_5 {
	background-color: rgb(253, 245, 234) !important;
}
.bg_row_color_6 {
	background-color: rgb(234, 253, 248) !important;
}
.bg_row_color_7 {
	background-color: rgb(243, 237, 250) !important;
}
.bg_row_color_8 {
	background-color: rgb(253 253 234) !important;
}

.description_flex {
	width: 100%;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.w_250 {
	width: 250px;
	min-width: 250px;
	max-width: 250px;
}

.w_123 {
	width: 123px !important;
	min-width: 123px !important;
	max-width: 123px !important;
}

.w_80 {
	width: 80px;
	min-width: 80px;
	max-width: 80px;
}

.bg_row_color_green {
	background-color: rgb(189, 220, 141, 0.3) !important;
}
.bg_row_color_red {
	background-color: rgba(233, 138, 114, 0.3) !important;
}

table thead tr.expandable_tr:hover td,
table thead tr.expandable_tr:focus td,
table thead tr.expandable_tr:active td,
table tbody tr.expandable_tr:hover td,
table tbody tr.expandable_tr:focus td,
table tbody tr.expandable_tr:active td,
table tfoot tr.expandable_tr:hover td,
table tfoot tr.expandable_tr:focus td,
table tfoot tr.expandable_tr:active td {
	background-color: rgba(73, 86, 99, 0.1) !important;
}

table thead tr th.td_span,
table thead tr td.td_span,
table tbody tr th.td_span,
table tbody tr td.td_span,
table tfoot tr th.td_span,
table tfoot tr td.td_span {
	height: 0;
	padding: 0;
	opacity: 0;
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	border-bottom-width: 0;
}
table thead tr th.td_span .expandable_content,
table thead tr td.td_span .expandable_content,
table tbody tr th.td_span .expandable_content,
table tbody tr td.td_span .expandable_content,
table tfoot tr th.td_span .expandable_content,
table tfoot tr td.td_span .expandable_content {
	width: 100%;
	height: 0;
	opacity: 0;
	overflow: hidden;
}
table thead tr th.td_span.toggle,
table thead tr td.td_span.toggle,
table tbody tr th.td_span.toggle,
table tbody tr td.td_span.toggle,
table tfoot tr th.td_span.toggle,
table tfoot tr td.td_span.toggle {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 0;
	opacity: 1;
	transition: all 0.2s ease-in-out;
}
table thead tr th.td_span.toggle .expandable_content,
table thead tr td.td_span.toggle .expandable_content,
table tbody tr th.td_span.toggle .expandable_content,
table tbody tr td.td_span.toggle .expandable_content,
table tfoot tr th.td_span.toggle .expandable_content,
table tfoot tr td.td_span.toggle .expandable_content {
	padding: 30px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	background-color: #e9edf0;
	opacity: 1;
	overflow: visible;
	transition: all 0.2s ease-in-out;
}
table thead tr th.td_span.toggle .expandable_content table,
table thead tr td.td_span.toggle .expandable_content table,
table tbody tr th.td_span.toggle .expandable_content table,
table tbody tr td.td_span.toggle .expandable_content table,
table tfoot tr th.td_span.toggle .expandable_content table,
table tfoot tr td.td_span.toggle .expandable_content table {
	box-shadow: 0px 0px 20px rgba(48, 57, 68, 0.3);
	background-color: #fff;
}
table thead tr th.td_span.toggle .expandable_content table tr:last-child td,
table thead tr td.td_span.toggle .expandable_content table tr:last-child td,
table tbody tr th.td_span.toggle .expandable_content table tr:last-child td,
table tbody tr td.td_span.toggle .expandable_content table tr:last-child td,
table tfoot tr th.td_span.toggle .expandable_content table tr:last-child td,
table tfoot tr td.td_span.toggle .expandable_content table tr:last-child td {
	border-bottom-width: 0;
}
.maxHeight_156 {
	max-height: 156px !important;
}
@media (min-width: 914px) {
	.hide_desktop {
		display: none !important;
	}
}
@media (max-width: 914px) {
	.app_content.f_small .main_content .scroll_container {
		height: calc(100vh - 190px) !important;
	}
	.app_content.f_small .main_content {
		height: calc(100vh - 180px) !important;
	}
	.padding_mobile {
		padding: 20px;
	}
	.flexDirectionColumn {
		flex-direction: column;
	}
	.sport_filter_mobile {
		display: flex !important;
		flex-direction: column !important;
		height: 300px !important;
		overflow-y: scroll !important;
		position: absolute !important;
		top: 45px !important;
		left: -4px !important;
		width: 42px !important;
		overflow-x: hidden !important;
		box-shadow: 0 6px 15px 2px rgb(85 99 115 / 30%);
		border-radius: 4px;
	}
	.hide {
		display: none !important;
	}
	.height_auto {
		height: auto !important;
	}
	.overflow_visible_mobile {
		overflow: visible !important;
	}
	.x_scroll {
		display: block;
		overflow-x: auto !important;
		white-space: nowrap !important;
		margin-bottom: 40px !important;
		margin-top: 20px !important;
	}

	.x_scroll_ls {
		display: block;
		overflow-x: auto !important;
		white-space: nowrap !important;
		margin-bottom: 0px !important;
		margin-top: 0px !important;
	}
	.x_scroll_footer {
		padding: 10px;
		height: auto !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/* position: absolute;
		bottom: 0; */
	}
	.remove_margin_on_mobile {
		margin-bottom: 0 !important;
	}
	.filter_label_mobile {
		width: 100px;
	}
	.max_width_100 {
		width: 100% !important;
	}
	.z_index_mobile {
		z-index: 1000 !important;
	}
	.flex_content {
		display: flex;
		flex-direction: column;
	}

	table thead tr:hover td,
	table thead tr:focus td,
	table thead tr:active td,
	table tbody tr:hover td,
	table tbody tr:focus td,
	table tbody tr:active td,
	table tfoot tr:hover td,
	table tfoot tr:focus td,
	table tfoot tr:active td,
	.table_row:hover,
	.table_row:active {
		background-color: #ffcb51 !important;
	}

	.empty_div {
		padding: 40px 0;
	}

	.h_500 {
		height: 500px !important;
	}
}
.icon_info {
	font-size: 20px;
}
.info_cell {
	display: 'flex';
	justify-content: 'center';
}
.filter_options_mobile {
	width: 100%;
	display: flex;
	justify-items: flex-start;
	justify-content: flex-start;
	padding: 5px;
}
.filter_section_mobile {
	display: flex;
	flex-direction: row;
}
.mobile_border {
	display: flex;
	flex-direction: column;
	padding: 10px;
	justify-content: left;
	align-items: flex-start;
	border-bottom: 1px solid rgba(141, 162, 182, 0.3);
}
.mobile_border_center {
	display: flex;
	flex-direction: column;
	padding: 10px;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid rgba(141, 162, 182, 0.3);
}
.filterOptionsMobile {
	display: flex;
	justify-content: center;
}

.sticky {
	position: sticky;
	top: 0;
	z-index: 2;
}

@media (max-width: 914px) {
	.overflow_x {
		overflow-x: auto !important;
		display: block;
		white-space: nowrap !important;
	}

	.modal-body_mobile {
		width: 100vw !important;
	}

	.modal-footer_mobile {
		width: 100vw !important;
		align-self: normal !important;
	}

	.w_1800 {
		width: 1800px !important;
	}

	.w_2200 {
		width: 2200px !important;
	}

	.w_2000 {
		width: 2000px !important;
	}

	.x_scroll__m_0 {
		display: block;
		overflow-x: auto !important;
		white-space: nowrap !important;
		margin-bottom: 0px !important;
		margin-top: 0px !important;
	}

	.closeBtnMobile {
		top: -10px !important;
		left: -14px !important;
	}

	.form_col input.mobile_outline {
		border: 1px solid rgba(143, 164, 184, 0.4) !important;
	}

	.right_panel_empty_div {
		padding: 120px 0;
	}

	.padding_bottom_120_mobile {
		padding-bottom: 120px !important;
	}

	.bg_white {
		background-color: #fff !important;
	}

	.w_fit_content_mobile {
		width: fit-content !important;
	}

	.margin_left_64_mobile {
		margin-left: 64px !important;
	}
}

.hidden_content {
	display: none !important;
}

.flex_center {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.green_text {
	color: green !important;
}

.red_text {
	color: red !important;
}

.line_height_18 {
	line-height: 18px !important;
}

.w_40 {
	width: 40px !important;
}

.w_80 {
	width: 80px !important;
}

.w_400 {
	width: 400px !important;
}
.w_120 {
	width: 120px !important;
}

.fit_content {
	width: fit-content;
}

.checkboxStyle {
	height: 17px !important;
	width: 17px !important;
	border: none !important;
	border-radius: 3px;
	outline: none;
	cursor: pointer;
}

.hidden_content {
	display: none !important;
}

.flex_center {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.static {
	position: static;
}

.black_dark_text {
	color: #242930 !important;
}

.light_blue_bg {
	background-color: #8fcbdd !important;
}

.light_green_bg {
	background-color: #8ec7a6 !important;
}

.light_yellow_bg {
	background-color: #fcd989 !important;
}

.light_red_bg {
	background-color: #e7abab !important;
}

.light_purple_bg {
	background-color: #d9bbdb !important;
}

.light_gray_bg {
	background-color: #ececec !important;
}

.font_size_14 td {
	font-size: 14px !important;
}

.light_gray_bg td {
	background-color: #ececec !important;
}

.static {
	position: static;
}

.reset_style table {
	text-align: center;
}

.reset_style td {
	border: none;
	border-collapse: collapse;
	border-spacing: 0;
}

.reset_style table thead tr:hover td,
.reset_style table thead tr:focus td,
.reset_style table thead tr:active td,
.reset_style table tbody tr:hover td,
.reset_style table tbody tr:focus td,
.reset_style table tbody tr:active td,
.reset_style table tfoot tr:hover td,
.reset_style table tfoot tr:focus td,
.reset_style table tfoot tr:active td {
	background-color: transparent;
}

.clock {
	display: flex;
	flex-direction: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: fit-content;
	font-size: 11px;
	align-items: center;
	justify-content: space-between;
	color: #e1f0ff;
	padding: 2px 6px;
}

.clock i {
	padding-right: 2px;
}

.promotion-dropdown {
	position: absolute;
	top: 21px;
	left: 64px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: fit-content;
	max-height: 130px !important;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0px 0;
	background-color: #fff;
	box-shadow: 0 6px 15px 2px rgba(85, 99, 115, 0.3);
}

.promotion-dropdown::-webkit-scrollbar {
	display: none;
}

.promotion-dropdown-list {
	padding: 4px;
	border-bottom: 1px solid #e2e8ed;
	background-color: #fff;
}

.minWidth_108 {
	min-width: 108px;
}

.link_look {
	text-decoration: underline;
	color: #19a6ea;
	cursor: pointer;
}

tr.dark_gray td {
	font-weight: 600;
	font-size: 14px !important;
	background-color: #e1f0ff !important;
}

table.autorisation_live td .period {
	width: fit-content;
}

.period_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 452px;
	height: 51.5px !important;
	overflow-y: hidden;
	overflow-x: auto;
}

table.autorisation_live td .competitors {
	display: block;
	max-width: 376px !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
}

table.autorisation_live td .competitors .home_team,
table.autorisation_live td .competitors .away_team {
	display: block;
	overflow: hidden !important;
	white-space: nowrap !important ;
	text-overflow: ellipsis !important;
	width: 100% !important;
}

.tooltip {
	padding-left: 10px;
	color: #037ff3;
}

.tooltip:hover {
	display: flex !important;
	flex-direction: column !important;
	align-items: start !important;
}

.tooltip .tooltiptext {
	visibility: hidden;
	height: 0;
	display: none !important;
}

.tooltip:hover .tooltiptext {
	height: fit-content !important;
	display: block !important;
	visibility: visible;
}

.flex_center {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.flex_left {
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
}

.flex_right {
	display: flex !important;
	justify-content: flex-end !important;
	align-items: center !important;
}
.ellipsis_dropdown {
	display: block !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	padding-top: 8px !important;
}

.badge_number {
	display: flex;
	align-content: center;
	justify-content: center;
	color: #fff;
	width: 20px;
	height: 20px;
	background-color: red;
	border-radius: 50%;
	padding: 2px;
}

.affiliate_title {
	font-size: 20px;
	font-weight: 400;
	margin: 10px 10px 20px 10px;
	text-transform: uppercase;
}

.uppercase {
	text-transform: uppercase;
}

.overflow_x_full_w {
	overflow-x: auto !important;
	display: block;
	white-space: nowrap !important;
}
